<template>
  <PageWithLayout>
    <TableView>
      <template v-slot:tbl_colgroup>
        <col style="width:190px" />
        <col />
      </template>
      <!-- <template v-slot:tbl_top>
        <div class="area_left">
          <strong class="tit_tbl">공지사항 {{ viewModel.isModify ? '수정' : '등록' }}</strong>
        </div>
      </template> -->
      <template v-slot:tbl_body>
        <tr>
          <th>상태</th>
          <td>
            <SwitchCheck
              :value.sync="statusSync"
              :isSwitchLeft="true"
              text="활성화"
              offText="비활성화"
            />
          </td>
        </tr>
        <tr>
          <th>이미지</th>
          <td>
            <FileUpload
              :dataList.sync="viewModel.model.thumbnail"
              :canMultiple="false"
              :acceptList="['jpg', 'jpeg', 'png']"
              :size="[1680, 880]"
              sizeType="FIT"
              :saveTrigger="thumbnailSaveTrigger"
              :isSaved.sync="thumbnailIsSaved"
            />
          </td>
        </tr>
        <tr>
          <th>SNS 공유 이미지</th>
          <td>
            <FileUpload
              :dataList.sync="viewModel.model.shareThumbnail"
              :canMultiple="false"
              :acceptList="['jpg', 'jpeg', 'png']"
              :size="[880, 880]"
              sizeType="FIT"
              :saveTrigger="shareThumbnailSaveTrigger"
              :isSaved.sync="shareThumbnailIsSaved"
            />
          </td>
        </tr>
        <tr>
          <th>
            제목<IconSvg iconName="essential" :size="6" iconColor="#F04848" />
          </th>
          <td>
            <Input
              placeholder="공지사항 제목을 입력해주세요"
              :value.sync="viewModel.model.title"
            />
          </td>
        </tr>
        <tr>
          <th>
            내용<IconSvg iconName="essential" :size="6" iconColor="#F04848" />
          </th>
          <td>
            <EditorTiptap
              :menuDataList="[
                'bold',
                'italic',
                'strike',
                'underline',
                'horizontalRule',
                'highlight',
                'link',
                'image',
                'youtube',
                'title',
                'bulletList',
                'orderedList',
                'blockquote',
                'clear_format',
                'undo',
                'redo',
              ]"
              :maxLength="1000000"
              :value.sync="viewModel.model.contents"
              :imageFileSize="[1680, 1680]"
              imageFileSizeType="MIN"
            />
          </td>
        </tr>
      </template>
    </TableView>
    <Sticky>
      <div class="area_left">
        <Button
          btnSize="medium"
          btnStyle="secondary_border"
          text="취소"
          :routeName="
            viewModel.isModify
              ? 'LANDING_BLOG_NOTICE_DETAIL'
              : 'LANDING_BLOG_NOTICE_LIST'
          "
          :routeQuery="{
            pageSize: $route.query.pageSize,
            page: $route.query.page,
          }"
        />
        <Button
          btnSize="medium"
          btnStyle="secondary"
          text="미리보기"
          @onClickBtn="viewModel.onClickNoticePreviewPopup()"
        />
      </div>
      <div class="area_right">
        <Button
          btnSize="medium"
          btnStyle="primary"
          text="저장"
          :disabled="isVaild"
          @onClickBtn="onClickComplete()"
        />
      </div>
    </Sticky>
    <template v-slot:popup>
      <NoticePreviewPopup
        v-if="viewModel.isNoticePreviewPopup"
        :detailData="viewModel.model"
        @onClickClose="viewModel.onClickCloseNoticePreviewPopup()"
      />
    </template>
  </PageWithLayout>
</template>

<script>
import PageWithLayout from '@lemontree-ai/lemontree-admin-common-front/components/layout/PageWithLayout';
import TableView from '@lemontree-ai/lemontree-admin-common-front/components/common/table/TableView';
import IconSvg from '@lemontree-ai/lemontree-admin-common-front/components/common/icon/IconSvg';
import SwitchCheck from '@lemontree-ai/lemontree-admin-common-front/components/common/switch/SwitchCheck';
import FileUpload from '@lemontree-ai/lemontree-admin-common-front/components/common/file/FileUpload';
import Input from '@lemontree-ai/lemontree-admin-common-front/components/common/input/Input';
import EditorTiptap from '@lemontree-ai/lemontree-admin-common-front/components/common/editor/EditorTiptap';
import Sticky from '@lemontree-ai/lemontree-admin-common-front/components/common/sticky/Sticky';
import Button from '@lemontree-ai/lemontree-admin-common-front/components/common/button/Button';
// popup
import NoticePreviewPopup from '@/views/landing/blog/notice/view/popup/NoticePreviewPopup';
// viewModel
import NoticeDetailViewModel from '@/views/landing/blog/notice/viewModel/NoticeDetailViewModel';
// mixin
import FileSaveMixin from '@lemontree-ai/lemontree-admin-common-front/mixins/FileSaveMixin';

export default {
  name: 'NoticeRegister',
  components: {
    PageWithLayout,
    TableView,
    IconSvg,
    SwitchCheck,
    FileUpload,
    Input,
    EditorTiptap,
    Sticky,
    Button,

    NoticePreviewPopup,
  },
  mixins: [FileSaveMixin],
  data() {
    return {
      viewModel: new NoticeDetailViewModel(),
      thumbnailSaveTrigger: false,
      thumbnailIsSaved: false,
      shareThumbnailSaveTrigger: false,
      shareThumbnailIsSaved: false,
    };
  },
  computed: {
    statusSync: {
      get() {
        return this.viewModel.model.status === 'NORMAL';
      },
      set(val) {
        let result = this.viewModel.model.status;
        switch (val) {
          case true: {
            result = 'NORMAL';
            break;
          }
          case false: {
            result = 'HIDDEN';
            break;
          }
        }
        this.viewModel.model.status = result;
      },
    },
    isVaild() {
      return this.viewModel.registerValidatedResult();
    },
  },
  watch: {
    thumbnailIsSaved() {
      if (this.thumbnailIsSaved) {
        this.checkFileUpload();
      }
    },
    shareThumbnailIsSaved() {
      if (this.shareThumbnailIsSaved) {
        this.checkFileUpload();
      }
    },
  },
  beforeMount() {
    // 복제하여 등록
    if (
      this.$route.name === 'LANDING_BLOG_NOTICE_REGISTER' &&
      this.$route.query.copyId
    ) {
      this.viewModel.initCopyRegister(this.$route.query.copyId);
    } else {
      const id = this.$route.params.id;
      this.viewModel.init(id);
    }
  },
  methods: {
    onClickComplete() {
      const isThumbnailUpload = Boolean(
        this.viewModel.model.thumbnail.length > 0 && this.viewModel.model.thumbnail[0].file,
      );
      const isShareTumbnailImageUpload = Boolean(
        this.viewModel.model.shareThumbnail.length > 0 && this.viewModel.model.shareThumbnail[0].file,
      );

      this.thumbnailSaveTrigger = isThumbnailUpload;
      this.shareThumbnailSaveTrigger = isShareTumbnailImageUpload;

      if (!isThumbnailUpload && !isShareTumbnailImageUpload) {
        this.onCompleteFileSave();
      }
    },
    checkFileUpload() {
      var fileVaild = true;
      if (this.thumbnailSaveTrigger && !this.thumbnailIsSaved)
        fileVaild = false;
      if (this.shareThumbnailSaveTrigger && !this.shareThumbnailIsSaved)
        fileVaild = false;
      if (fileVaild) {
        this.onCompleteFileSave();
      }
    },
    onCompleteFileSave() {
      if (this.viewModel.isModify) {
        this.viewModel.putNoticeDetail();
      } else {
        this.viewModel.postNoticeDetail();
      }
    },
  },
};
</script>
<style scoped>
.letter_preview {
  padding: 15px;
  background-color: rgba(0, 0, 0, 0.8);
}
.letter_preview .inner_preview {
  display: inline-flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  max-height: calc(100vh - 100px);
  min-height: 100px;
  padding: 40px 0 60px;
  border-radius: 18px;
  background-color: #fff;
  white-space: normal;
  vertical-align: middle;
  box-sizing: border-box;
  text-align: center;
}

.letter_preview .layer_head {
  padding: 0 66px 20px;
}
.letter_preview .layer_head .tit_layer {
  font-weight: 700;
  font-size: 24px;
  line-height: 46px;
  color: #333;
}

.letter_preview .layer_body {
  overflow-y: auto;
  max-height: 500px;
  padding: 0 66px;
  font-weight: 600;
  text-align: left;
}
</style>
